.cta {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: $padding-percent;
  background: white;
  height: 600px;
}

.cta-title {
  @include h3;
  text-align: center;
  margin-bottom: 3rem;
  max-width: 45ch;
}
