$small:  480px;
$medium: 768px;
$large:  1024px;
$xlarge: 1200px;

@mixin above($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin below($width) {
  @media screen and (max-width: $width - 1) {
    @content;
  }
}
