.subnav {
  height: 150px;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: $smoke;
  animation: fadeIn 300ms 500ms $ease backwards;
  @include below($small) { display: none; }
}

.back-arrow {
  font-size: 1.75em;
  vertical-align: middle;
  margin-right: 0.5rem;
  display: inline-block;
  transition: color 200ms $ease, transform 200ms $bounce;
}

a:hover .back-arrow {
  color: $twilio-red;
  transform: translateX(-0.5rem);
}
