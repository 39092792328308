// ============================
//  Timing Functions
// ============================

// Quickly accelerate and slowly decelerate between locations.
$ease: cubic-bezier(0.4, 0.0, 0.2, 1);
// Start at full velocity and slowly decelerate to a resting point.
$ease-out: cubic-bezier(0.0, 0.0, 0.2, 1);
// Quickly accelerate at the beginning and level out at end.
$ease-in: cubic-bezier(0.4, 0.0, 1, 1);
// Pulls back then accelerates quickly beyond final resting point.
$bounce: cubic-bezier(0.5, -0.5, 0.5, 1.5);

// ============================
//  Keyframe Animations
// ============================

@keyframes fadeIn {
  0% { opacity: 0; }
}

@keyframes slideInUp {
  0% { opacity: 0; transform: translateY(1.5rem); }
}

@keyframes slideInLeft {
  0% { opacity: 0; transform: translateX(1.5rem); }
}
