.get-notified {
  background: white;
  padding: 3rem 2rem;
  text-align: center;
}

.get-notified-form {
  input[type="email"] {
    margin-bottom: 1rem;
    font-size: $h6;
  }

  input, button {
    width: 75%;
    text-align: center;
  }
}

.get-notified-title {
  color: $twilio-red;
  -webkit-font-smoothing: antialiased;
}

.get-notified-subtitle {
  color: $smoke;
  max-width: 28ch;
  margin: 0 auto;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.form-success-message {
  animation: slideInUp 300ms $bounce;
}

.check-back {
  color: $dusk;
}
