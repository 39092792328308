.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  background-color: $chalk;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 350px;
  position: relative;
  color: white;
  animation: slideInUp 750ms $ease backwards;
}

@for $i from 1 to 20 {
  .grid-item:nth-child(#{$i})  {
    animation-delay: calc(#{$i} * 100ms);
  }
}

// ============================
//  Doer Card
// ============================

.doer-card {
  @include fill-container;
  padding-left: 2rem;
  padding-right: 2rem;
}

.doer-card:after {
  @include image-overlay;
}

.doer-card-content {
  position: relative;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  top: 80%;
  transform: translateY(-50%);
  z-index: 20;
  transition: top 200ms;
}

.doer-card-name {
  @include h4;
}

.doer-card-description {
  @include h6;
  line-height: 1.4;
  opacity: 0.6;
}

.doer-card-image {
  pointer-events: none;
  width: 100%;
  min-height: 100%;
  // prevents Flex item from squishing image
  object-fit: cover;
  transition: filter 400ms;
  z-index: 0;
}

.grid-item:hover {
  .doer-card:after { background-color: fade-out($twilio-blue, 0.2); }
  .doer-card-content { top: 50%; }
  .doer-card-image { filter: saturate(0); }
}

.doer-card--new:before {
  content: 'New!';
  @include badge-red;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 50;
}
