@mixin input-reset {
  appearance: none;
  border: none;
  border-radius: 0;
  outline: none;
  white-space: nowrap;

  // font-size: 16px stops iOS from zooming on focus
  // while still allows the user to zoom manually
  // http://stackoverflow.com/a/16255670
  font-size: 16px;
  font-family: inherit;
  color: $dusk;
  background-color: $ash;
  padding: 1rem;
  height: 50px;

  &:focus {
    box-shadow: inset 0 0 0 1px $twilio-red;
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.input,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
  @include input-reset;
}

input[type="checkbox"],
input[type="color"],
input[type="file"],
input[type="image"],
input[type="radio"],
input[type="range"] {
  // TODO: custom style these things
}

// ============================
//  Search box
// ============================

.input--search {
  padding-right: 2.5rem;
  width: 100%;
  background-position: right 1rem center;
  background-repeat: no-repeat;
  background-size: 18px;
  background-image: url(/img/icon-search.svg);
}

// if this is really bad for accessibility,
// we can do padding-right: 2.5rem to .search-input
// to avoid conflicting with the search icon
input::-webkit-search-cancel-button {
  appearance: none;
}
