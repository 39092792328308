$btn-default-height: 50px;
$btn-small-height: 30px;
$btn-large-height: 60px;

@mixin btn-base {
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  // make sure buttons sizes don't
  // go any smaller than the content
  height: $btn-default-height;
  line-height: $btn-default-height;
  min-width: min-content;
  text-align: center;
  padding-left: 2em;
  padding-right: 2em;

  @include uppercase;
  font-family: inherit;
  font-size: 0.6rem;
  color: $white;
  background-color: $twilio-red;
  transition-property: background-color, box-shadow, color;
  transition-duration: 100ms;
  backface-visibility: hidden;

  &:hover {
    color: $white;
    background: darken($twilio-red, 10%);
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.btn,
button,
input[type="button"],
input[type="submit"] {
  @include btn-base;
}

.btn--secondary {
  background-color: $dusk;
  &:hover {
    background: $twilio-red;
  }
}

.btn--small {
  height: $btn-small-height;
  flex-shrink: 1;
  padding-left: 1.25em;
  padding-right: 1.25em;
}

.btn--outline {
  color: $dusk;
  font-weight: 500;
  background-color: transparent !important;
  box-shadow: inset 0 0 0 2px $dusk;
  &:hover {
    color: $twilio-red;
    box-shadow: inset 0 0 0 2px $twilio-red;
  }
}

.btn--outline-red {
  @extend .btn--outline;
  color: $white;
  box-shadow: inset 0 0 0 2px $twilio-red;
}

.btn--outline-white {
  @extend .btn--outline;
  color: $white;
  box-shadow: inset 0 0 0 2px $white;
}

.btn--large {
  height: $btn-large-height;
  line-height: $btn-large-height;
  width: 100%;
  font-size: 1rem;
  text-transform: none;
  letter-spacing: initial;
  font-weight: lighter;
  @include above($small) {
    font-size: 1.3rem;
    width: auto;
  }
}

.secondary-action {
  padding-top: 1em;
  text-align: center;
  a {
    @include underline;
    white-space: nowrap;
  }
}
