// Twilio Dark
// https://highlightjs.org/
// https://highlightjs.readthedocs.io/en/latest/index.html

$code-blue: #92BFBF;
$code-paleblue: #BDDCDC;
$code-green: #B4D388;
$code-yellow: #f9ed7b;
$code-orange: #ea7956;
$code-red: #CF5340;

// ============================
//  Code
// ============================

pre, code {
  font-family: $mono-font;
  line-height: 1.6;
  font-style: normal;
  tab-size: 2;
}

pre {
  color: $ash;
  background-color: $twilio-blue;
  overflow: auto;
  position: relative;
  white-space: pre-wrap;
}

pre code {
  display: inline-block;
  margin: 2rem;
  @include below($medium) {
    white-space: pre;
  }
}

// Only style <code> when not a child of <pre>
:not(pre) > code {
  color: #333;
  background-color: $ash;
  line-height: 1;
  overflow: auto;
  padding: 0.2rem 0.4rem;
  padding: 0.75ch 1ch 0.5ch;
  border-radius: 3px;
}

// ============================
//  HighlightJS / https://highlightjs.org/
// ============================

.hljs-comment,
.hljs-symbol,
.hljs-bullet,
.hljs-addition {
  color: darken($smoke, 10%);
}

.hljs-variable,
.hljs-template-variable,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class,
.hljs-regexp,
.hljs-deletion {
  color: $code-green;
}

.hljs-attribute,
.hljs-attr,
.hljs-literal,
.hljs-type,
.hljs-meta,
.hljs-params,
.hljs-link {
  color: $code-yellow;
}

.hljs-title,
.hljs-section,
.hljs-tag,
.hljs-quote,
.hljs-string {
  color: $code-blue;
}

.hljs-number,
.hljs-built_in,
.hljs-builtin-name,
.hljs-keyword,
.hljs-selector-tag {
  color: $code-orange;
}

.hljs-emphasis {
  font-style: italic
}

.lang-none * {
  color: $ash !important;
}

// ============================
//  Language Labels
// ============================

code.lang-cs,
code.lang-css,
code.lang-dotnet,
code.lang-html,
code.lang-java,
code.lang-js,
code.lang-markup,
code.lang-php,
code.lang-python,
code.lang-ruby,
code.lang-scss,
code.lang-shell,
code.lang-swift {
  &:after {
    position: absolute;
    top: 0;
    right: 0;
    @include badge-base;
    background-color: fade-out($dusk, 0.5);
  }
}

code.lang-cs:after { content: "C#"; }
code.lang-css:after { content: "CSS"; }
code.lang-dotnet:after { content: ".NET"; }
code.lang-html:after { content: "HTML"; }
code.lang-java:after { content: "Java"; }
code.lang-js:after { content: "JavaScript"; }
code.lang-markup:after { content: "Markup"; }
code.lang-php:after { content: "PHP"; }
code.lang-python:after { content: "Python"; }
code.lang-ruby:after { content: "Ruby"; }
code.lang-scss:after { content: "SCSS"; }
code.lang-shell:after { content: "Shell"; }
code.lang-swift:after { content: "Swift"; }
