* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

a {
	text-decoration: none;
	color: inherit;
}

img  {
  width: auto;
  max-width: 100%;
  display: block;
}

svg  {
  max-height: 100%
}
