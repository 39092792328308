.not-found-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 120px);
  padding: 1rem;
  animation: slideInUp 750ms $ease backwards;
}

.not-found {
  text-align: center;
}

.not-found-header {
  @include h6;
  display: inline-block;
  color: $twilio-red;
  border: 1px solid;
  line-height: 1.2;
  margin-bottom: 2rem;
  white-space: nowrap;
}

.not-found-code,
.not-found-title {
  display: inline-block;
  padding: 1rem;
}

.not-found-code {
  border-right: 1px solid;
}

.not-found-body p {
  @include below($small) {
    display: inline;
  }
}
