.header-small {
  background-color: $chalk;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 120px;
  padding-left: $padding-fixed * 2;
  padding-right: $padding-fixed * 2;
  overflow-x: hidden;
  @include below(550px) {
    justify-content: center;
  }

  .header-title {
    display: flex;
    align-items: center;
    font-size: 3.2rem;
    margin-bottom: 0;

    .icon-draw-the-owl {
      width: 50px;
      margin-right: 1.25rem;
      vertical-align: text-bottom;
    }
  }

  .header-link {
    position: static;
    @include below(550px) {
      display: none;
    }
  }
}
