.doer-profile {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  border-bottom: 1px solid $ash;
  @include above(700px) {
    flex-direction: row;
  }
}

.doer-column-left,
.doer-column-right {
  flex: 1;
  margin-bottom: 3rem;
  animation: slideInUp 750ms $ease backwards;
}

.doer-column-left {
  min-width: 200px;
  @include above(700px) {
    max-width: 20vw;
    margin-right: 75px;
  }
}

.doer-column-right {
  animation-delay: 200ms;
  @include above(1400px) {
    min-width: 700px;
  }
}

.doer-image-overlay {
  position: relative;
  margin-bottom: 3rem;
  &:after { @include image-overlay; }
  @include mobile-fullwidth;
  @include above($small) {
    border-radius: 3px;
    overflow: hidden;
  }
  @include above(1400px) {
    max-width: 400px;
  }
}

.doer-image {
  width: 100%;
}

.doer-name {
  @include h1-large;
  margin-top: -18px;
  @include below(700px) {
    display: none;
  }
}

.doer-tagline {
  @extend h5;
  margin-bottom: 2rem;
  color: $smoke;
}

.doer-twitter {
  @include badge-base;
  display: inline-block;
  background-color: $twitter;
  color: white;
  padding: 0.75em 1em;
  border-radius: 3px;
  margin-bottom: 2rem;
  .icon-twitter {
    fill: currentcolor;
    width: 14px;
    vertical-align: middle;
    transform: translateY(-1px);
    margin-right: 1ch;
  }
}

.doer-intro {
  @extend h3;
}

.doer-name-over-image {
  @include h1-large;
  @include fill-container;
  font-size: $base-font-size * 4;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  z-index: 20;
  @include above(700px) {
    display: none;
  }
}

.doer-intro {
  margin-bottom: 3rem;
}

.doer-body p {
  margin-bottom: 2rem;
}

.doer-body img,
.doer-body video,
.doer-body iframe {
  margin-bottom: 2rem;
  @include mobile-fullwidth;
}

.doer-body p:last-child:after {
  display: inline-block;
  content: url(/img/twilio-logomark.svg);
  vertical-align: middle;
  transform: translateY(-2px);
  line-height: 0;
  height: 14px;
  width: 14px;
  margin-left: 1ch;
}

.other-doers {
  @include mobile-fullwidth;
  text-align: center;
  color: $smoke;
}

.other-doers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  background-color: $chalk;
}


.other-doers-grid .grid-item {
  @include below(750px) {
    min-width: 100%;
    max-height: 400px;
    overflow: hidden;
  }
}

.other-doers-title {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
