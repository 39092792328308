.header {
  position: relative;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
  animation: fadeIn 1s $ease;
}

.header-link {
  @include h6;
  position: absolute;
  top: 2rem;
  right: 2rem;
  padding: 1rem;
  white-space: nowrap;
  & svg {
    vertical-align: text-top;
    transition: transform 300ms $bounce;
  }
  @include below($small) {
    top: 1rem;
    right: 1rem;
  }
}

.header-link:before {
  display: inline-block;
  content: url(/img/twilio-logomark.svg);
  vertical-align: middle;
  line-height: 0;
  height: 14px;
  width: 14px;
  margin-right: 1ch;
  opacity: 0;
  transform: translateX(2ch) translateY(-2px);
  transition: transform 300ms $bounce, opacity 200ms $ease;
}

.header-link:hover:before {
  opacity: 1;
  transform: translateX(0) translateY(-2px);
}

.header-link:hover svg {
  transform: rotate(45deg);
}

.header-link-text {
  margin-right: 0.5rem;
}

@keyframes owly {
  0% { transform: scale(1.05) rotate(5deg); }
  50% { transform: scale(0.95) rotate(-5deg); }
}

.header-title {
  @include h1-large;
  margin-bottom: 2rem;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  .icon-draw-the-owl {
    margin-right: 1.5rem;
    vertical-align: bottom;
    height: auto;
    transition: fill 200ms $ease;
    &:hover {
      fill: $twilio-red;
      animation: owly 400ms backwards;
    }
  }
  @include below(620px) {
    font-size: 5rem;
    .icon-draw-the-owl {
      width: 60px;
      margin-right: 1.2rem;
    }
  }
  @include below(400px) {
    font-size: 3.5rem;
    line-height: initial;
    .icon-draw-the-owl {
      width: 50px;
      margin-right: 1rem;
    }
  }
}

.header-subtitle {
  max-width: 75ch;
}

.header-subtitle + .header-subtitle {
  margin-top: 2rem;
}
