$footer-break: 830px;

.footer {
  margin-top: 10%;
  padding: 4rem;
  @include above($large) {
    padding: 5rem;
  }
}

.footer {
  background: $twilio-blue;
  text-align: center;
}

.footer-statement {
  font-family: $serif-font;
  font-size: 1.75rem;
  font-style: italic;
  color: $dusk;
  line-height: 1.2;
  letter-spacing: 1px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  margin-bottom: 5rem;
  @include above($large) {
    font-size: 2.5rem;
  }
  @include above($xlarge) {
    font-size: 3rem;
  }
}

.footer-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin-top: 1rem;
  @include above($footer-break) {
    flex-direction: row;
  }
}

.footer-list-item {
  display: flex;
  color: $dusk;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  white-space: nowrap;
  position: relative;
  @include below($footer-break) {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}

.footer-list-item:not(:last-child):after {
  content: "";
  display: none;
  align-self: center;
  height: 10px;
  margin-left: 15px;
  margin-right: 15px;
  border-right: 1px solid fade-out($dusk, 0.35);
  @include above($footer-break) {
    display: flex;
  }
}

.footer-link {
  color: $dusk;
  cursor: pointer;
  &:hover {
    color: $smoke;
  }
}

.footer-link .icon-globe {
  fill: currentcolor;
  transform: translateY(2px);
  margin-right: 5px;
}

.language-selector {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translateY(25px);
  transition-property: opacity, visibility, transform;
  transition-duration: 200ms;
  transition-timing-funcion: $bounce;

  position: absolute;
  bottom: 200%;
  left: 0;
  right: 0;
  margin: auto;
  background: #222840;
  padding: 20px 26px;
}

.language-selector:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: #222840 transparent transparent;
  border-width: 10px 14px;
}

.language-selector.is-open {
  opacity: 1;
  visibility: visible;
  pointer-events: default;
  transform: translateY(0);
}

.language-link {
  color: $white;
  display: block;
  width: 140px;
  padding: 2px 0;
  font-weight: 300;
  text-transform: none;
  letter-spacing: 0;
  text-align: left;
}
