$twilio-red:  #F22F46; // Brand color
$twilio-blue: #0D122B; // Dark blue
$dusk:        #565B73; // Faded Blue
$smoke:       #94979B; // Dark grey
$ash:         #E8E8E8; // Middle grey
$chalk:       #F5F5F5; // Light grey
$cerulean:    #008CFF; // Bright blue
$white:       #FFFFFF;

$voice-video: #36D576; // Green
$messaging:   #F47C22; // Orange
$auth:        #EFDC2E; // Yellow
$wireless:    #7D60A9; // Purple
$tools:       #21A4C9; // Blue
$quickstarts: #91B7CB; // Lightblue
$guides:      #B0766F; // Coral
$tutorials:   #BDAD87; // Gold
$api:         #5CA391; // Aqua

$twitter: #1DA1F2;
$facebook: #3B5998;

$border: fade-out($smoke, 0.5);

// ============================
//  Color Application
// ============================

html {
  color: $twilio-blue;
  background: url(/img/dot-pattern.svg), $ash;
  background-attachment: fixed;
}

body {
  background: $white;
}

.twilio-logotype,
.twilio-logomark {
  fill: $twilio-red;
}

::selection {
  background: $twilio-red;
  color: $white;
  text-shadow: none;
}

// ============================
//  Color Utilities
// ============================

.color-twilio-red  { color: $twilio-red; }
.color-twilio-blue { color: $twilio-blue; }
.color-white       { color: $white; }
.color-dusk        { color: $dusk; }
.color-smoke       { color: $smoke; }
.color-ash         { color: $ash; }
.color-chalk       { color: $chalk; }
.color-cerulean    { color: $cerulean; }
.color-inherit     { color: inherit; }
.color-transparent { color: transparent; }

.bg-twilio-red  { background-color: $twilio-red; }
.bg-twilio-blue { background-color: $twilio-blue; }
.bg-white       { background-color: $white; }
.bg-dusk        { background-color: $dusk; }
.bg-smoke       { background-color: $smoke; }
.bg-ash         { background-color: $ash; }
.bg-chalk       { background-color: $chalk; }
.bg-cerulean    { background-color: $cerulean; }
.bg-transparent { color: transparent; }
