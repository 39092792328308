// Use @mixin rather than @extend
// https://www.sitepoint.com/avoid-sass-extend/

// This might be useful to use
// for converting small px to rem
// e.g. border-radius: rem(3px);
@function rem($size) {
  @return #{$size/16px}rem;
}

@mixin link {
  color: $twilio-red;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  // backface-visibility improves smoothness of transitions
  // http://tympanus.net/codrops/css_reference/backface-visibility
  backface-visibility: hidden;
  transition: color 100ms;
  &:hover { color: $twilio-red; }
  svg { fill: currentcolor; }
}

@mixin underline($border-color: $twilio-red) {
  text-decoration: none;
  text-shadow: 0.03em 0 #fff, -0.03em 0 #fff, 0 0.03em #fff, 0 -0.03em #fff, 0.06em 0 #fff, -0.06em 0 #fff, 0.09em 0 #fff, -0.09em 0 #fff, 0.12em 0 #fff, -0.12em 0 #fff, 0.15em 0 #fff, -0.15em 0 #fff;
  background-image: linear-gradient(to bottom, $border-color 50%, transparent 0%);
  background-repeat: repeat-x;
  background-size: 2px 2px;
  background-position: 0 bottom;
  transition: color 100ms;
  &:hover { color: $twilio-red; }
}

@mixin uppercase {
  text-transform: uppercase;
  letter-spacing: 0.15em;
}

@mixin badge-base {
  @include uppercase;
  font-family: $base-font;
  font-size: $base-font-size * 0.6;
  padding: 0.2em 0.6em;
}

@mixin badge-red {
  @include badge-base;
  background-color: $twilio-red;
  color: $white;
}

@mixin tag {
  @include badge-base;
  box-shadow: inset 0 0 0 1px $border;
}

//  Set parent element to postition: relative
//  and then apply this mixin to the child
@mixin fill-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
}

@mixin image-overlay {
  @include fill-container;
  content: '';
  background-image: linear-gradient(fade-out($twilio-blue, 1), fade-out($twilio-blue, 0.4));
  transition: background-color 400ms;
  z-index: 10;
}

//  Make element full-width by breaking
//  outside .inner-container padding/margins
@mixin mobile-fullwidth {
  @include below($small) {
    width: 100vw !important;
    min-width: 100vw !important;
    margin-left: -$padding-fixed !important;
    margin-right: -$padding-fixed !important;
  }
}
