.doer-products-header {
  @include h6;
  display: block;
  color: $smoke;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-top: 1px solid $ash;
  border-bottom: 1px solid $ash;
  margin-bottom: 2rem;
  @include below(700px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.doer-products {
  list-style: none;
}

.doer-product {
  display: flex;
  height: 60px;
  align-items: center;
  &:hover svg path {
    fill: inherit;
  }
}

.product-icon {
  display: flex;
  justify-content: center;
  min-width: 50px;
  margin-right: 1rem;
  svg path {
    transition: fill 100ms;
    fill: $dusk;
  }
}

.product-name {
  @include h6;
  @include uppercase;
  font-weight: 500;
  line-height: 1.4;
}
