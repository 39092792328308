@keyframes rotate {
 100% { transform: rotate(360deg); }
}

@keyframes dash {
 0% { stroke-dasharray: 1,200; stroke-dashoffset: 0; }
 50% { stroke-dasharray: 89,200; stroke-dashoffset: -35; }
 100% { stroke-dasharray: 89,200; stroke-dashoffset: -124; }
}

.loading {
  @include fill-container;
  background-color: $twilio-blue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.loading-spinner {
  width: 50px;
  height: 50px;
}

.loading-spinner svg {
  position: relative;
  animation: rotate 2s linear infinite;
  height: 50px;
  width: 50px;
}

.loading-spinner circle {
  stroke: $twilio-red;
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}
