// ============================
//  Typography
// ============================

$base-font: 'Whitney SSm A', 'Whitney SSm B', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$serif-font: 'Surveyor SSm A', 'Surveyor SSm B', Times New Roman, serif;
$condensed-font: 'Knockout 27 A', 'Knockout 27 B', Times New Roman, serif;
$mono-font: Lekton, Monaco, Consolas, monospace;

$base-font-size: 1rem;
$base-line-height: 1.8;
$base-font-weight: 400;

// In order to use predictable rem units
// we reset the html font size in case
// the browser has custom font size settings.
html {
  font-size: 14px;
  @include above($small) { font-size: 15px; }
  @include above($medium) { font-size: 16px; }
  // Prevent iOS automatic font size adjustment -> http://stackoverflow.com/a/6210818
  text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}

.body, body {
  font-family: $base-font;
  font-size:   $base-font-size;
  line-height: $base-line-height;
  font-weight: $base-font-weight;
}

// ============================
//  Font Sizing
// ============================

// Editing $base-font-size allows us to
// proportionally scale all type sizes
$h1: $base-font-size * 2.4;
$h2: $base-font-size * 2.0;
$h3: $base-font-size * 1.6;
$h4: $base-font-size * 1.4;
$h5: $base-font-size * 1.0;
$h6: $base-font-size * 0.8;

$fine-print: 13px;

@mixin h1-large {
  font-family: $condensed-font;
  font-size: $base-font-size * 6.2;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  line-height: 1;
}

@mixin h1 {
  font-family: $base-font;
  font-weight: 300;
  font-size: $h1;
  line-height: 1.3;
}

@mixin h2 {
  font-family: $base-font;
  font-weight: 300;
  font-size: $h2;
  line-height: 1.4;
}

@mixin h3 {
  font-family: $base-font;
  font-weight: 400;
  font-size: $h3;
  line-height: 1.5;
}

@mixin h4 {
  font-family: $base-font;
  font-weight: 400;
  font-size: $h4;
  line-height: 1.6;
}

@mixin h5 {
  font-family: $base-font;
  font-weight: 500;
  font-size: $h5;
  line-height: $base-line-height;
  @include uppercase;
}

@mixin h6 {
  font-family: $base-font;
  font-weight: 500;
  font-size: $h6;
  line-height: $base-line-height;
  @include uppercase;
}

.h1, h1 { @include h1 }
.h2, h2 { @include h2 }
.h3, h3 { @include h3 }
.h4, h4 { @include h4 }
.h5, h5 { @include h5 }
.h6, h6 { @include h6 }
.fine-print { font-size: $fine-print; }

.h1, h1,
.h2, h2,
.h3, h3,
.h4, h4,
.h5, h5,
.h6, h6,
p {
  a {
    @include underline;
    // prevents long links from breaking layout
    word-break: break-word;
  }
}

.link {
  @include link;
}
